<template>
    <section class="py-5" style="padding-top: 0rem !important;background: #9DB0B0;">
        <top-home />
    </section>
    <section class="pb-5">
        <puzzles-home />
    </section>
    <section class="pb-5">
        <strategy-home/>
    </section>
    <section class="pb-5">
        <sports-home/>
    </section>
    <section class="pb-5">
        <adventure-home/>
    </section>
    <section class="pb-5">
        <arcade-home/>
    </section>
</template>

<script>
import AdventureHome from './HomePage/AdventureHome.vue'
import ArcadeHome from './HomePage/ArcadeHome.vue'
import PuzzlesHome from './HomePage/PuzzlesHome.vue'
import SportsHome from './HomePage/SportsHome.vue'
import StrategyHome from './HomePage/StrategyHome.vue'
import TopHome from './HomePage/TopHome.vue'
export default {
  components: { TopHome, PuzzlesHome, StrategyHome, SportsHome, AdventureHome, ArcadeHome },
   name: 'AppHome',
}
</script>

<style>

</style>